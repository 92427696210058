.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem 1.3rem 2rem 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 200px; /* You can adjust this value to your preferred height */
    width: 100%;
}

.portfolio__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will maintain aspect ratio while filling the container */
    object-position: center; /* Centers the image within the container */
}

.portfolio__item h3 {
    margin: 0.5rem 0 1.5rem;
    height: 3.6rem; /* Adjust this value based on your needs */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* This will show maximum 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    /* margin-bottom: 1rem; */
    justify-content: center;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
