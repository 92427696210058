header {
    height: 50rem;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ============= CTA ============= */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ============= HEADER SOCIALS ============= */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ============= ME ============= */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 24rem;
    height: 24rem;
    position: absolute;
    left: calc(50% - 12rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 12rem 12rem;
    overflow: hidden;
    padding: 1.5rem 2rem 1rem 2rem;
}

/* ============= SCROLL DOWN ============= */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 12rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ============= */
@media screen and (max-width: 1024px) {
    /* None */
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ============= */
@media screen and (max-width: 600px) {
    header {
        height: 40rem;
        padding-top: 3rem;
    }

    .me {
        background: linear-gradient(var(--color-primary), transparent);
        width: 16rem;
        height: 16rem;
        left: calc(50% - 8rem);
        margin-top: 4rem;
        border-radius: 8rem 8rem 8rem 8rem;
        overflow: hidden;
        padding: 1rem 2rem 0rem 2rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}
